import React from 'react';
import OptionPanel from '../components/option-panel';
import FunnelAction from '../components/funnel-action';
import Buying from '../images/buying.svg';
import Refinancing from '../images/refinancing.svg';
import SlideInPage from '../components/slidein-page';
import { buildLink } from '../modules/build-link';
import { OwnUpBox, OwnUpText } from '@rategravity/own-up-component-library';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

const StartNowPage = () => {
  const builtLink = buildLink(`${process.env.ONBOARDING_URL}/refinance`);
  const beforeText = (
    <>
      Rates in our marketplace beat 90% of offers out there, which means our customers save $20,000+
      over the life of their loan.
    </>
  );
  const afterText = (
    <>
      <span className="optionPanel__subTitle">About Own Up</span>
      <br />
      Own Up’s marketplace gets you market-beating rates representing thousands in savings.
      <br />
      <br />
      You’ll also get expert advice from our team of Home Advisors. They don’t work for a lender, so
      they can provide personalized, unbiased advice that’s in your best interest. And we guarantee
      no spam or pushy service.
    </>
  );
  return (
    <OwnUpComponentLibraryProvider theme={ownUpTheme}>
      <OwnUpGridWrapper>
        <DiscoverGridContainer spacing={2}>
          <SlideInPage title={'Own Up | Get the best deal on your mortgage'} index>
            <OptionPanel
              title="A great mortgage rate is a few clicks away."
              subtitle="Rates vary widely. It pays to shop."
              {...{ beforeText, afterText }}
              className="optionpanel__startNow"
            >
              <FunnelAction text="Buying" src={Buying} to="buying" />
              <div className={`funnelAction`}>
                <a className="funnelAction__link" href={builtLink}>
                  <img {...{ src: Refinancing }} />
                  <h5 className="funnelAction__text"> Refinancing </h5>
                </a>
              </div>
            </OptionPanel>
          </SlideInPage>
        </DiscoverGridContainer>
      </OwnUpGridWrapper>
    </OwnUpComponentLibraryProvider>
  );
};

export default StartNowPage;
