import React from 'react';
import { Helmet } from 'react-helmet';
import { SlideIn } from '../transitions';

const SlideInPage = ({ children, title, index = false, shouldAnimate }) => (
  <SlideIn shouldAnimate={shouldAnimate}>
    <Helmet>
      <title>{title}</title>
      <meta name="robots" content={index ? 'index' : 'noindex'} />
    </Helmet>
    {children}
  </SlideIn>
);

export default SlideInPage;
