import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useMemo } from 'react';
import { OwnUpGridContainerProps } from './properties';

/**
 * A wrapper around Material UI's {@link Grid} element with the `container`
 *   property forced on. Defines and responsively changes the sizing
 *   of the gutters based on the current screen size.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://material-ui.com/api/grid/}
 *
 * @param props - {@link OwnUpGridContainerProps}
 */
export const OwnUpGridContainer = ({ spacing, ...props }: OwnUpGridContainerProps) => {
  // Check if the current breakpoint of the screen is small or below.
  const screenSizeSmallOrBelow = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Determine what spacing parameter to use
  const trueSpacing = useMemo<GridSpacing>(() => {
    // If spacing is overridden in the props, just use that.
    if (spacing !== undefined) {
      return spacing;
    }
    // Otherwise, return spacing based on the screen size:
    if (screenSizeSmallOrBelow) {
      // Use a space factor of 2 (8px * 2 = 16px) on smaller screens
      return 2;
    }
    // Use a space factor of 3 (8px * 3 = 24px) on larger screens
    return 3;
  }, [screenSizeSmallOrBelow, spacing]);

  // Return a Grid container with the spacing options and any other
  //   props passed forward.
  return <Grid container spacing={trueSpacing} {...props} />;
};
