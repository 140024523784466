import React, { useMemo } from 'react';
import OptionPanel from '../components/option-panel';
import FunnelAction from '../components/funnel-action';
import Buying from '../images/buying.svg';
import Refinancing from '../images/refinancing.svg';
import SlideInPage from '../components/slidein-page';
import { buildLink } from '../modules/build-link';
import { trackingInstance } from '../modules/1pt-observer';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

const GetStartedPage = () => {
  const builtLink = buildLink(`${process.env.ONBOARDING_URL}/refinance`);

  return (
    <OwnUpComponentLibraryProvider theme={ownUpTheme}>
      <OwnUpGridWrapper>
        <DiscoverGridContainer spacing={2}>
          <SlideInPage title={'Own Up | Get the best deal on your mortgage'} index>
            <OptionPanel title="What brings you here today?">
              <FunnelAction text="Buying" src={Buying} to="buying" />
              <div className={`funnelAction`}>
                <a className="funnelAction__link" href={builtLink}>
                  <img {...{ src: Refinancing }} />
                  <h5 className="funnelAction__text"> Refinancing </h5>
                </a>
              </div>
            </OptionPanel>
          </SlideInPage>
        </DiscoverGridContainer>
      </OwnUpGridWrapper>
    </OwnUpComponentLibraryProvider>
  );
};

export default GetStartedPage;
