import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { p22MackinacBookMixin, p22MackinacMediumMixin } from '../../fonts/mixins/p22-mackinac';
import { OwnUpHeadlineProps } from './properties';

const largeHeadlineMixinMobile = css`
  font-weight: 300;
  font-size: 3rem;
  line-height: 3.5rem;
`;

const largeHeadlineMixinDesktop = css`
  font-size: 4rem;
  line-height: 4.5rem;
`;

const headlineMixinMobile = css`
  font-size: 2.5rem;
  line-height: 3rem;
`;

const headlineMixinDesktop = css`
  font-size: 3rem;
  line-height: 3.5rem;
`;

const smallHeadlineMixinMobile = css`
  font-size: 2rem;
  line-height: 2.5rem;
`;

const smallHeadlineMixinDesktop = css`
  font-size: 2.5rem;
  line-height: 3rem;
`;

const Headline = (props: OwnUpHeadlineProps) => <Typography {...props} />;

export const OwnUpLargeHeadline = styled(Headline)<PropsWithTheme<OwnUpHeadlineProps>>`
  ${p22MackinacBookMixin}
  ${largeHeadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${largeHeadlineMixinDesktop}
  }
`;

export const OwnUpLargeHeadlineMedium = styled(Headline)`
  ${p22MackinacMediumMixin}
  ${largeHeadlineMixinMobile}
  font-weight: 500;

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${largeHeadlineMixinDesktop}
  }
`;

export const OwnUpHeadline = styled(Headline)`
  ${p22MackinacBookMixin}
  ${headlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${headlineMixinDesktop}
  }
`;

export const OwnUpHeadlineMedium = styled(Headline)`
  ${p22MackinacMediumMixin}
  ${headlineMixinMobile}

  font-weight: 500;

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${headlineMixinDesktop}
  }
`;

export const OwnUpHeadlineSmall = styled(Headline)`
  ${p22MackinacBookMixin}
  ${smallHeadlineMixinMobile}
  font-weight: 300;

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${smallHeadlineMixinDesktop}
  }
`;

export const OwnUpSmallHeadlineMedium = styled(Headline)`
  ${p22MackinacMediumMixin}
  ${smallHeadlineMixinMobile}
  font-weight: 500;
  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${smallHeadlineMixinDesktop}
  }
`;
