import React, { useMemo } from 'react';
import styled from 'styled-components';
import { OwnUpIconProps } from './properties';

/**
 * Span wrapper for the icon
 */
const OwnUpStyledIcon = styled.span<
  // Span props has all the same fields we need from the base
  //   Icon props except height, so we'll pick that as well
  Pick<OwnUpIconProps, 'height'>
>`
  display: flex;
  & svg {
    width: ${({ height }) => (height ? height : '1.25rem')};
    height: ${({ height }) => (height ? height : '1.25rem')};
  }
  & path {
    fill: ${({ color }) => color};
  }
`;

/**
 * Render a span that contains a defined icon.
 *
 * @param props See {@link OwnUpIconProps}
 */
export const OwnUpIcon = ({ icon: Icon, spanRef, ...props }: OwnUpIconProps) => {
  // Icon could be a functional component or a simple JSX element.
  //   Use this helper function to handle either.
  const renderedIcon = useMemo(() => (typeof Icon === 'function' ? <Icon /> : Icon), [Icon]);
  return (
    <OwnUpStyledIcon ref={spanRef} {...props}>
      {renderedIcon}
    </OwnUpStyledIcon>
  );
};
