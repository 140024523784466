import { Typography } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { aktivGroteskMediumMixin, aktivGroteskRegularMixin } from '../../fonts';
import { OwnUpHeadlineProps } from '../headline';
import { OwnUpSubheadlineProps } from './properties';

const largeSubheadlineMixinMobile = css`
  font-size: 2rem;
  line-height: 2.5rem;
`;

const largeSubheadlineMixinDesktop = css`
  font-size: 2.5rem;
  line-height: 3rem;
`;

const subheadlineMixinMobile = css`
  font-size: 1.75rem;
  line-height: 2.25rem;
`;

const subheadlineMixinDesktop = css`
  font-size: 2rem;
  line-height: 2.5rem;
`;

const smallSubheadlineMixinMobile = css`
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

const smallSubheadlineMixinDesktop = css`
  font-size: 1.5rem;
  line-height: 2rem;
`;

const Subheadline = (props: OwnUpSubheadlineProps) => <Typography {...props} />;

export const OwnUpLargeSubheadline = styled(Subheadline)<PropsWithTheme<OwnUpSubheadlineProps>>`
  ${aktivGroteskRegularMixin}
  ${largeSubheadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpSubheadlineProps>) => theme.breakpoints.up('lg')} {
    ${largeSubheadlineMixinDesktop}
  }
`;

export const OwnUpLargeSubheadlineMedium = styled(Subheadline)<
  PropsWithTheme<OwnUpSubheadlineProps>
>`
  ${aktivGroteskMediumMixin}
  ${largeSubheadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpSubheadlineProps>) => theme.breakpoints.up('lg')} {
    ${largeSubheadlineMixinDesktop}
  }
`;

export const OwnUpSubheadline = styled(Subheadline)<PropsWithTheme<OwnUpHeadlineProps>>`
  ${aktivGroteskRegularMixin}
  ${subheadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${subheadlineMixinDesktop}
  }
`;

export const OwnUpSubheadlineMedium = styled(Subheadline)<PropsWithTheme<OwnUpHeadlineProps>>`
  ${aktivGroteskMediumMixin}
  ${subheadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${subheadlineMixinDesktop}
  }
`;

export const OwnUpSmallSubheadline = styled(Subheadline)<PropsWithTheme<OwnUpHeadlineProps>>`
  ${aktivGroteskRegularMixin}
  ${smallSubheadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${smallSubheadlineMixinDesktop}
  }
`;

export const OwnUpSmallSubheadlineMedium = styled(Subheadline)<PropsWithTheme<OwnUpHeadlineProps>>`
  ${aktivGroteskMediumMixin}
  ${smallSubheadlineMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpHeadlineProps>) => theme.breakpoints.up('lg')} {
    ${smallSubheadlineMixinDesktop}
  }
`;
