import * as queryString from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import { useLocation } from 'react-router-dom';

/**
 * Combines any number of query bodies in order.
 *
 * @param  {...any} queries - query objects. Strings or
 *   objects are acceptable.
 */
const buildQuery = (...queries: Array<string | ParsedUrlQueryInput | undefined>) => {
  // Iterate over every object
  const combinedQueryObject = queries.reduce((acc: ParsedUrlQueryInput, cur) => {
    // Ignore empty or undefined bodies
    if (!cur) {
      return acc;
    }
    let queryItem: ParsedUrlQueryInput;
    // If they're strings...
    if (typeof cur === 'string') {
      // ...convert them to objects by removing any
      // leading ?'s and parsing the string.
      queryItem = queryString.parse(cur.replace(/^\?/, ''));
    } else {
      queryItem = cur;
    }
    // Then return the combined object.
    return {
      ...acc,
      ...queryItem
    };
  }, {});
  return queryString.stringify(combinedQueryObject);
};

/**
 * Tool for building a link with query parameters.
 *
 * Will also append the existing query parameters
 *   if present.
 *
 * @param {*} to - Path to go to -- can also include
 *   query parameters
 * @param {*} query - Query parameters to append to the
 *   link
 */
export const buildLink = (to: string, query?: string | ParsedUrlQueryInput) => {
  // Parse out link path and query params (if any)
  const [link, linkQuery] = to.split('?');

  // Grab the existing search string
  const existingQuery = useLocation().search;

  // Build and stringify a query body based on all inputs
  const builtQuery = buildQuery(existingQuery, linkQuery, query);

  // Append if it wasn't empty
  if (builtQuery) {
    return `${link}?${builtQuery}`;
  }

  // Just return the link otherwise.
  return link;
};
