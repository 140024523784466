/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme } from '@material-ui/core/styles';
import { buttonTheme, ButtonThemeTokens } from '../../buttons/theme';
import {
  ATLANTIC_100,
  BERRY_100,
  CHARCOAL_100,
  FOG_20,
  MINT_100,
  PINE_100,
  WHITE_100
} from '../../colors';
import { checkboxTheme, CheckboxThemeTokens } from '../../inputs/checkbox/theme';
import { inputTheme } from '../../inputs/text-input/theme';
import { InputThemeTokens } from '../../inputs/text-input/theme';
import { linkTheme, LinkThemeTokens } from '../../links/theme';

import {
  aktivGroteskBoldFontDefinition,
  aktivGroteskMediumFontDefinition,
  aktivGroteskRegularFontDefinition,
  monostenCFontDefinition,
  p22MackinacBookFontDefinition,
  p22MackinacMediumFontDefinition
} from './fonts';

// extend Theme to allow custom proporties
declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    name: string;
    inputColors: InputThemeTokens;
    linkColors: LinkThemeTokens;
    checkboxColors: CheckboxThemeTokens;
    buttonColors: ButtonThemeTokens;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    name: string;
    inputColors: InputThemeTokens;
    linkColors: LinkThemeTokens;
    checkboxColors: CheckboxThemeTokens;
    buttonColors: ButtonThemeTokens;
  }
}

export const ownUpWhiteTheme = createTheme({
  name: 'own-up-white',
  /**
   * Custom breakpoints a defined in ZeroHeight:
   *   {@link https://zeroheight.com/7b3231350/p/973579-breakpoints}
   *
   * Numbers indicate the _lower_ bound of the breakpoint.
   *
   * Material UI Documentation: {@link https://material-ui.com/customization/breakpoints/}
   */
  breakpoints: {
    values: {
      xs: 0,
      sm: 512,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 4
  },
  typography: {
    fontFamily: '"Aktiv-Grotesk-Regular", Helvetica, sans-serif',
    fontSize: 16
  },
  inputColors: inputTheme.white,
  linkColors: linkTheme.white,
  checkboxColors: checkboxTheme.white,
  buttonColors: buttonTheme.white,
  palette: {
    primary: {
      main: PINE_100
    },
    secondary: {
      main: ATLANTIC_100
    },
    error: {
      main: BERRY_100
    },
    background: {
      default: WHITE_100
    },
    text: {
      primary: CHARCOAL_100
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // Global font declarations
        '@font-face': [
          aktivGroteskBoldFontDefinition,
          aktivGroteskMediumFontDefinition,
          aktivGroteskRegularFontDefinition,
          monostenCFontDefinition,
          p22MackinacBookFontDefinition,
          p22MackinacMediumFontDefinition
        ],
        // Apply to all elements
        '*': {
          boxSizing: 'border-box'
        },
        // Apply to body
        body: {
          overflowX: 'hidden',
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0
        }
      }
    }
  }
});

// ownUpWhiteTheme extensions
export const ownUpLightTheme = createTheme({
  ...ownUpWhiteTheme,
  name: 'own-up-light',
  inputColors: inputTheme.light,
  linkColors: linkTheme.light,
  checkboxColors: checkboxTheme.light,
  buttonColors: buttonTheme.light,
  palette: {
    background: {
      default: FOG_20
    },
    text: {
      primary: CHARCOAL_100
    }
  }
});

export const ownUpMidtoneTheme = createTheme({
  ...ownUpWhiteTheme,
  name: 'own-up-midtone',
  inputColors: inputTheme.midtone,
  linkColors: linkTheme.midtone,
  checkboxColors: checkboxTheme.midtone,
  buttonColors: buttonTheme.midtone,
  palette: {
    background: {
      default: MINT_100
    },
    text: {
      primary: CHARCOAL_100
    }
  }
});

export const ownUpDarkTheme = createTheme({
  ...ownUpWhiteTheme,
  name: 'own-up-dark',
  inputColors: inputTheme.dark,
  linkColors: linkTheme.dark,
  checkboxColors: checkboxTheme.dark,
  buttonColors: buttonTheme.dark,
  palette: {
    background: {
      default: ATLANTIC_100
    },
    text: {
      primary: WHITE_100
    }
  }
});
