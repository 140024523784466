import { useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMemo } from 'react';

/**
 * Determine what breakpoint the user's screen is currently
 *   within. Does this by performing a series of media queries.
 */
export const useBreakpoint = (): Breakpoint => {
  const theme = useTheme();
  // Because of React hook rules, hooks must always be done
  //   in the same order, meaning we cannot terminate early
  //   even if we have determined which breakpoint we're at
  //   before running all of them.
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));

  // Return the breakpoint that is currently "true"
  return useMemo(() => {
    if (isXs) {
      return 'xs';
    }
    if (isSm) {
      return 'sm';
    }
    if (isMd) {
      return 'md';
    }
    if (isLg) {
      return 'lg';
    }
    // If no other breakpoints match, we can assume we're at XL
    return 'xl';
  }, [isXs, isSm, isMd, isLg]);
};
