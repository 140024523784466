/* eslint-disable import/extensions */
import MonostenC from '../../assets/fonts/monosten/Monosten C.otf';

export const monostenCFontDefinition = {
  fontFamily: 'Monosten',
  src: `url("${MonostenC}") format("opentype")`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'swap'
} as const;
