import React from 'react';
import * as colors from '../../../colors';

export const ArrowLeftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46939 15.7806C6.76228 16.0735 7.23716 16.0735 7.53005 15.7806C7.82294 15.4877 7.82294 15.0129 7.53005 14.72L3.5601 10.75H18.25C18.6642 10.75 19 10.4142 19 10C19 9.5858 18.6642 9.25001 18.25 9.25001H3.56012L7.53005 5.28009C7.82294 4.98719 7.82294 4.51232 7.53005 4.21943C7.23716 3.92653 6.76228 3.92653 6.46939 4.21943L1.21912 9.46969C0.926227 9.76259 0.926227 10.2375 1.21912 10.5304L6.46939 15.7806Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
