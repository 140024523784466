import styled from 'styled-components';
import { PropsWithTheme } from '../core';
import { createElementOutlineMixin } from '../core/theme/mixins/element-outline';
import { focusVisibleSelectorMixin } from '../core/theme/mixins/focus-visible-selector';
import { DivProps } from './properties';
import { iconLinkSizes } from './theme';

// spacing
const linkIconSpacing = '8px';

// radius
const linkBackgroundRadius = '4px';
const linkFocusRadius = '4px';
export const IconLinkWrapper = styled.div<PropsWithTheme<DivProps>>`
  width: fit-content;
  .MuiLink-root svg path {
    fill: ${(props: PropsWithTheme<DivProps>) => props.theme.linkColors.linkIconColor};
  }
  .MuiLink-root${focusVisibleSelectorMixin} {
    ${(props: PropsWithTheme<DivProps>) =>
      createElementOutlineMixin({
        radius: linkBackgroundRadius,
        width: '2px',
        color: props.theme.linkColors.linkOutlineColor
      })}
  }
  .MuiLink-root:hover {
    background-color: ${(props: PropsWithTheme<DivProps>) =>
      props.theme.linkColors.linkHoverBackgroundColor};
    border-radius: ${linkFocusRadius};
  }
  .MuiTypography-colorPrimary {
    color: ${(props: PropsWithTheme<DivProps>) => props.theme.linkColors.linkFontColor};
  }
  .MuiLink-root svg {
    width: ${(props) => iconLinkSizes[props.size].linkSize};
    height: ${(props) => iconLinkSizes[props.size].linkSize};
    ${({ theme }: PropsWithTheme<DivProps>) => theme.breakpoints.up('lg')} {
      width: ${(props) => (props.size === 'large' ? '1.5rem' : '')};
      height: ${(props) => (props.size === 'large' ? '1.5rem' : '')};
    }
    margin-right: ${(props) => (props.iconPlacement === 'left' ? linkIconSpacing : 0)};
    margin-left: ${(props) => (props.iconPlacement === 'right' ? linkIconSpacing : 0)};
  }
`;
