import React from 'react';
import Pitch from '../components/pitch';
import { event } from '@rategravity/frontend/modules/user-actions';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

const ApprovedPage = ({ actionPipe }) => (
  <OwnUpComponentLibraryProvider theme={ownUpTheme}>
    <OwnUpGridWrapper>
      <DiscoverGridContainer spacing={0}>
        <Pitch
          title={
            <>
              Mortgage rates vary, so it pays to shop for financing.
              <br />
              Build your profile now.
            </>
          }
          subtitle="Start by answering some basic questions."
          buttonText="GET STARTED"
          onClick={() => {
            actionPipe.next(event('Click approved continue', {}));
          }}
          href={`${process.env.ONBOARDING_URL}/purchase`}
          pageTitle="Own Up | Buying - Get the best deal on your mortgage"
        />
      </DiscoverGridContainer>
    </OwnUpGridWrapper>
  </OwnUpComponentLibraryProvider>
);

export default ApprovedPage;
