import React, { useReducer } from 'react';
import Pitch from '../components/pitch';
// import colors from '@rategravity/frontend/theme/colors';
import { event } from '@rategravity/frontend/modules/user-actions';
import { ChevronDown, ChevronUp } from '../images/chevron';
import SlideInPage from '../components/slidein-page';

/**
 * This page doesn't show up on discover as it will automatically redirect to my.ownup.com.
 */
const OfferedPage = ({
  actionPipe,
  title = `That's great! Because you're planning on purchasing soon, we recommend getting a preapproval letter.`
}) => {
  const [display, showHide] = useReducer(d => (d === 'none' ? 'block' : 'none'), 'none');
  return (
    <SlideInPage title={'Own Up | Buying - Get the best deal on your mortgage'}>
      <Pitch
        title={title}
        shouldAnimate={false}
        subtitle={
          <div
            style={{ maxWidth: '700px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <span>This takes less than five minutes and doesn't require a hard credit check.</span>
            <br />
            <br />
            <div>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#3533df',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '18.2px',
                  letterSpacing: 'normal',
                  paddingBottom: '5px',
                  border: 'none',
                  borderBottom: `2px solid #3533df`
                }}
                onClick={() => {
                  showHide(display === 'none' ? 'block' : 'none');
                }}
              >
                Why do I need a preapproval letter?
                <span>{display !== 'none' ? ChevronUp({}) : ChevronDown({})}</span>
              </button>
              <ul style={{ textAlign: 'left', display }}>
                <li style={{ paddingBottom: '7px' }}>
                  It lets sellers know you're a serious buyer and is required to submit an offer.
                </li>
                <li style={{ paddingBottom: '7px' }}>
                  It helps you understand what you can truly afford by analyzing your debt and
                  self-reported income.
                </li>
                <li style={{ paddingBottom: '7px' }}>
                  It helps you identify and act on any potential problems that may affect your
                  ability to get a loan.
                </li>
              </ul>
            </div>
          </div>
        }
        buttonText="GET STARTED"
        onClick={() => {
          actionPipe.next(event('Click preapproval continue', {}));
        }}
        href={`${process.env.ONBOARDING_URL}/preapproval`}
        pageTitle="Own Up | Buying - Get the best deal on your mortgage"
      />
    </SlideInPage>
  );
};

export default OfferedPage;
