import React from 'react';
import {
  Twitter,
  Facebook,
  Linkedin,
  Instagram,
  Medium
} from '@rategravity/frontend/components/icons';

export const SocialLinks = ({ copyHoverColor }) => {
  const className = `footer__social hover__color--${copyHoverColor}`;
  return (
    <div className="footer__socials">
      <a href="https://twitter.com/weareownup" {...{ className }}>
        <Twitter />
      </a>
      <a href="https://www.facebook.com/weareownup" {...{ className }}>
        <Facebook />
      </a>
      <a href="https://www.linkedin.com/company/own-up" {...{ className }}>
        <Linkedin />
      </a>
      <a href="https://www.instagram.com/weareownup" {...{ className }}>
        <Instagram />
      </a>
      <a href="https://medium.com/transforming-home-financing-to-benefit-the" {...{ className }}>
        <Medium />
      </a>
    </div>
  );
};
