import Link, { LinkProps } from '@material-ui/core/Link';
import { RefObject } from 'react';
import styled from 'styled-components';

// spacing
const linkBackgroundSpacing = '0 4px';

export const StyledLink = styled(Link)`
  padding: ${linkBackgroundSpacing};
  display: flex;
  place-items: center;
`;

type AdditionalStyleProps = {
  iconPlacement: 'left' | 'right';
  size: 'large' | 'default' | 'small';
};

export type DivProps = Omit<LinkProps, 'ref'> & AdditionalStyleProps;

export interface StyledIconLinkProps extends LinkProps {
  link: string;
  wrapperRef?: RefObject<HTMLDivElement> | null;
  styledLinkRef?: RefObject<HTMLDivElement> | null;
  iconPlacement?: 'left' | 'right';
  size?: 'large' | 'default' | 'small';
}
