import React from 'react';
import Pitch from '../components/pitch';
import { event } from '@rategravity/frontend/modules/user-actions';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

const AffordabilityPage = ({ actionPipe }) => {
  return (
    <OwnUpComponentLibraryProvider theme={ownUpTheme}>
      <OwnUpGridWrapper>
        <DiscoverGridContainer spacing={0}>
          <Pitch
            title="Excellent! Let's help you understand your true affordability."
            buttonText="GET STARTED"
            onClick={() => {
              actionPipe.next(event('Click to affordability calculator', {}));
            }}
            href={`${process.env.MY_OWNUP_URL}/affordability`}
            pageTitle="Own Up | Buying - Get the best deal on your mortgage"
          />
        </DiscoverGridContainer>
      </OwnUpGridWrapper>
    </OwnUpComponentLibraryProvider>
  );
};

export default AffordabilityPage;
