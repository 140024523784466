import * as colors from '../colors';
import { ThemeNames } from '../core/theme/types';

export const FILL_BUTTON_PRIMARY_THEME = 'fillButtonPrimaryTheme';
export const FILL_BUTTON_PRIMARY_ACCENT_THEME = 'fillButtonPrimaryAccentTheme';
export const ACTION_BUTTON_THEME = 'actionButtonTheme';
export const buttonThemeTypes = [
  FILL_BUTTON_PRIMARY_THEME,
  FILL_BUTTON_PRIMARY_ACCENT_THEME,
  ACTION_BUTTON_THEME
] as const;
export type ButtonThemeType = typeof buttonThemeTypes[number];

export type ButtonThemeTokens = {
  [key in ButtonThemeType]: {
    buttonFontColor: React.CSSProperties['color'];
    buttonIconColor: React.CSSProperties['color'];
    buttonBackgroundColorActive: React.CSSProperties['color'];
    buttonBackgroundColorHover: React.CSSProperties['color'];
    buttonBackgroundColorFocus: React.CSSProperties['color'];
    buttonFocusColor: React.CSSProperties['color'];
  };
};

type ButtonTheme = {
  [key in ThemeNames]: ButtonThemeTokens;
};

const commonColors = {
  buttonFontColor: colors.WHITE_100,
  buttonIconColor: colors.CACTUS_100,
  buttonBackgroundColorActive: colors.ALOE_100,
  buttonBackgroundColorHover: colors.PINE_100,
  buttonBackgroundColorFocus: colors.PINE_100,
  buttonFocusColor: colors.WHITE_100
};

const darkColors = {
  buttonFontColor: colors.CHARCOAL_100,
  buttonIconColor: colors.ALOE_100,
  buttonBackgroundColorActive: colors.SAGE_100,
  buttonBackgroundColorHover: colors.MINT_100,
  buttonBackgroundColorFocus: colors.MINT_100,
  buttonFocusColor: colors.ALOE_100
};

const commonPrimaryAccentColors = {
  buttonFontColor: colors.CHARCOAL_100,
  buttonIconColor: colors.CHARCOAL_100,
  buttonBackgroundColorActive: colors.MANGO_100,
  buttonBackgroundColorHover: colors.APRICOT_60,
  buttonBackgroundColorFocus: colors.APRICOT_60,
  buttonFocusColor: colors.CHARCOAL_100
};

export const buttonTheme: ButtonTheme = {
  [ThemeNames.White]: {
    [FILL_BUTTON_PRIMARY_THEME]: {
      ...commonColors
    },
    [FILL_BUTTON_PRIMARY_ACCENT_THEME]: {
      ...commonPrimaryAccentColors
    },
    [ACTION_BUTTON_THEME]: {
      ...commonColors,
      buttonBackgroundColorHover: colors.GRADIENT_ALOE_PINE,
      buttonBackgroundColorFocus: colors.GRADIENT_ALOE_PINE
    }
  },
  [ThemeNames.Light]: {
    [FILL_BUTTON_PRIMARY_THEME]: {
      ...commonColors
    },
    [FILL_BUTTON_PRIMARY_ACCENT_THEME]: {
      ...commonPrimaryAccentColors
    },
    [ACTION_BUTTON_THEME]: {
      ...commonColors,
      buttonBackgroundColorHover: colors.GRADIENT_ALOE_PINE,
      buttonBackgroundColorFocus: colors.GRADIENT_ALOE_PINE
    }
  },
  [ThemeNames.Midtone]: {
    [FILL_BUTTON_PRIMARY_THEME]: {
      ...commonColors
    },
    [FILL_BUTTON_PRIMARY_ACCENT_THEME]: {
      ...commonPrimaryAccentColors
    },
    [ACTION_BUTTON_THEME]: {
      ...commonColors,
      buttonBackgroundColorHover: colors.GRADIENT_ALOE_PINE,
      buttonBackgroundColorFocus: colors.GRADIENT_ALOE_PINE
    }
  },
  [ThemeNames.Dark]: {
    [FILL_BUTTON_PRIMARY_THEME]: {
      buttonFontColor: colors.CHARCOAL_100,
      buttonIconColor: colors.ALOE_100,
      buttonBackgroundColorActive: colors.SAGE_100,
      buttonBackgroundColorHover: colors.MINT_100,
      buttonBackgroundColorFocus: colors.MINT_100,
      buttonFocusColor: colors.ALOE_100
    },
    [FILL_BUTTON_PRIMARY_ACCENT_THEME]: {
      ...commonPrimaryAccentColors,
      buttonBackgroundColorActive: colors.MANGO_60,
      buttonBackgroundColorHover: colors.APRICOT_40,
      buttonBackgroundColorFocus: colors.APRICOT_40
    },
    [ACTION_BUTTON_THEME]: {
      ...darkColors,
      buttonBackgroundColorActive: colors.CACTUS_100,
      buttonBackgroundColorHover: colors.GRADIENT_MINT_CACTUS,
      buttonBackgroundColorFocus: colors.GRADIENT_MINT_CACTUS
    }
  }
};
