import Grid from '@material-ui/core/Grid';
import React from 'react';
import { OwnUpGridItemProps } from './properties';

/**
 * A wrapper around Material UI's {@link Grid} element with the
 *   `item` property forced on.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://material-ui.com/api/grid/}
 *
 * @param props - {@link OwnUpGridItemProps}
 */
export const OwnUpGridItem = (props: OwnUpGridItemProps) => <Grid item {...props} />;
