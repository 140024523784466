import React from 'react';
import Conditional from '@rategravity/frontend/components/conditional';
import './option-panel.styl';

const OptionPanel = ({ title, subtitle, children, className, beforeText, afterText }) => (
  <div className={`optionPanel ${className}`}>
    <h2 className="optionPanel__title">{title}</h2>
    <Conditional show={subtitle != undefined && subtitle != null}>
      <p className="optionPanel__subTitle copy--h5">{subtitle}</p>
    </Conditional>
    <Conditional show={beforeText != undefined && beforeText != null}>
      <p className="optionPanel__beforeText">{beforeText}</p>
    </Conditional>
    <div className="optionPanel__options">{children}</div>
    <Conditional show={afterText != undefined && afterText != null}>
      <p className="optionPanel__afterText">{afterText}</p>
    </Conditional>
  </div>
);

export default OptionPanel;
