/* eslint-disable import/extensions */
import P22MackinacBookEOT from '../../assets/fonts/p22-mackinac/P22MackinacBook.eot';
import P22MackinacBookWOFF from '../../assets/fonts/p22-mackinac/P22MackinacBook.woff';
import P22MackinacMediumEOT from '../../assets/fonts/p22-mackinac/P22MackinacMedium.eot';
import P22MackinacMediumWOFF from '../../assets/fonts/p22-mackinac/P22MackinacMedium.woff';

export const p22MackinacMediumFontDefinition = {
  fontFamily: 'P22-Mackinac-Medium',
  src: `url("${P22MackinacMediumEOT}?#iefix") format("embedded-opentype"),
         url("${P22MackinacMediumEOT}"),
         url("${P22MackinacMediumWOFF}") format("woff")`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'swap'
} as const;

export const p22MackinacBookFontDefinition = {
  fontFamily: 'P22-Mackinac-Book',
  src: `url("${P22MackinacBookEOT}?#iefix") format("embedded-opentype"),
         url("${P22MackinacBookEOT}"),
         url("${P22MackinacBookWOFF}") format("woff")`,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  fontDisplay: 'swap'
} as const;
