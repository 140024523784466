import React from 'react';
import OptionPanel from '../option-panel';
import Container from '@rategravity/frontend/components/container';
import Button from '@rategravity/frontend/components/button';
import './pitch.styl';
import SlideInPage from '../slidein-page';
import { buildLink } from '../../modules/build-link';

const PitchPage = ({
  title,
  subtitle,
  buttonText,
  onClick = () => {},
  href,
  pageTitle,
  query,
  shouldAnimate
}) => {
  const builtLink = buildLink(href, query);

  return (
    <SlideInPage title={pageTitle} shouldAnimate={shouldAnimate}>
      <OptionPanel {...{ title, subtitle }}>
        <Container className="pitch">
          <a className="pitch__link" href={builtLink} onClick={() => onClick()}>
            <Button className="pitch__button">
              <h6>{buttonText}</h6>
            </Button>
          </a>
        </Container>
      </OptionPanel>
    </SlideInPage>
  );
};
export default PitchPage;
