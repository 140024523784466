import React from 'react';
import OptionPanel from '../components/option-panel';
import FunnelAction from '../components/funnel-action';
import Affirmative from '../images/affirmative.svg';
import Negative from '../images/negative.svg';
import SlideInPage from '../components/slidein-page';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

const PreapprovedPage = () => {
  const { track } = useTrackingInstance();
  const { skip_approved_page_experiment: result } = useTreatments([
    'skip_approved_page_experiment'
  ]);
  const skipApprovedPage = result.treatment === 'On';

  return (
    <OwnUpComponentLibraryProvider theme={ownUpTheme}>
      <OwnUpGridWrapper>
        <DiscoverGridContainer spacing={2}>
          <SlideInPage title={'Own Up | Buying - Get the best deal on your mortgage'}>
            <OptionPanel title="Got it! Have you been pre&#65279;-&#65279;approved?">
              <FunnelAction
                text="Yes!"
                src={Affirmative}
                to={skipApprovedPage ? 'purchase' : 'approved'}
                onClick={() => {
                  track('discover-preapproved-clicked-yes');
                }}
              />
              <FunnelAction text="Not yet." src={Negative} to="preapproval" />
            </OptionPanel>
          </SlideInPage>
        </DiscoverGridContainer>
      </OwnUpGridWrapper>
    </OwnUpComponentLibraryProvider>
  );
};

export default PreapprovedPage;
