import React from 'react';
import {
  colors,
  OwnUpAppBar,
  OwnUpCallButton,
  OwnUpThemeWrapper
} from '@rategravity/own-up-component-library';

const Header = ({ className }) => (
  <OwnUpThemeWrapper className={className}>
    <OwnUpAppBar variant="minimal">
      <OwnUpCallButton variant={['header', 'headerAlt']} />
    </OwnUpAppBar>
  </OwnUpThemeWrapper>
);

export default Header;
