import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { aktivGroteskMediumMixin, aktivGroteskRegularMixin } from '../../fonts';
import { OwnUpBodyProps } from './properties';

const largeBodyMixinMobile = css`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const largeBodyMixinDesktop = css`
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

const bodyMixin = css`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const smallBodyMixin = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Body = (props: TypographyProps) => <Typography {...props} />;

export const OwnUpLargeBody = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskRegularMixin}
  ${largeBodyMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.breakpoints.up('lg')} {
    ${largeBodyMixinDesktop}
  }
`;

export const OwnUpLargeBodyMedium = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskMediumMixin}
  ${largeBodyMixinMobile}

  ${({ theme }: PropsWithTheme<OwnUpBodyProps>) => theme.breakpoints.up('lg')} {
    ${largeBodyMixinDesktop}
  }
`;

export const OwnUpBody = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskRegularMixin}
  ${bodyMixin}
`;

export const OwnUpBodyMedium = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskMediumMixin}
  ${bodyMixin}
`;

export const OwnUpBodyItalic = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskRegularMixin}
  ${bodyMixin}
  font-style: italic;
`;

export const OwnUpSmallBody = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskRegularMixin}
  ${smallBodyMixin}
`;

export const OwnUpSmallBodyMedium = styled(Body)<PropsWithTheme<OwnUpBodyProps>>`
  ${aktivGroteskMediumMixin}
  ${smallBodyMixin}
`;
