import React from 'react';
import PropTypes from 'prop-types';
import { ColorEnum } from '../prop-types';
import { NavLinks } from './nav';
import { SocialLinks } from './social';
import './footer.styl';

const Footer = ({ className, copyHoverColor }) => (
  <div className={`container page__center ${className}`}>
    <NavLinks {...{ copyHoverColor }} />
    <br />
    <SocialLinks {...{ copyHoverColor }} />
  </div>
);

Footer.defaultProps = {
  backgroundColor: 'grey-light',
  copyColor: 'primary',
  copyHoverColor: 'secondary'
};

Footer.propTypes = {
  backgroundColor: ColorEnum,
  copyColor: ColorEnum,
  className: PropTypes.string,
  copyHoverColor: ColorEnum
};

export default Footer;
