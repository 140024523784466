import originalFetch from 'cross-fetch';
import fetchRetry from 'fetch-retry';
import { v4 as uuid } from 'uuid';
import { PageData } from './get-page-data';
import { Logger } from './logger-provider';
import { loggerProvider } from './logger-provider';

const fetch = fetchRetry(originalFetch) as typeof originalFetch;

export interface Metadata {
  ts: number;
  rid: string;
}

export interface Context {
  page: PageData;
  userAgent: string;
}

export interface Event {
  anonymousId: string;
  context: Context;
  type: 'track' | 'page' | 'identify';
  properties: Record<string, unknown>;
  event?: string;
  accountId?: string;
}

interface EnhancedEvent extends Event {
  meta: Metadata;
}

interface PostEventProps {
  url: string;
  logger: Logger;
  decoratePayload?: (payload: Event, getMeta?: () => Metadata) => Event;
}

const meta = () => ({ ts: Date.now(), rid: uuid() });

const addMetadata = (payload: Event, getMeta = meta): EnhancedEvent => ({
  ...payload,
  meta: getMeta()
});

export const postEvent =
  ({ logger, url, decoratePayload = addMetadata }: PostEventProps) =>
  (payload: Event) => {
    const stringifiedPayload = JSON.stringify(decoratePayload(payload));
    logger(`Payload: \n ${stringifiedPayload}`);
    fetch(url, {
      method: 'post',
      body: stringifiedPayload,
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      headers: { 'Content-Type': 'application/json' }
    }).catch((err) => logger(`Error: \n ${err}`));
  };

export const postFactory = (url: string, debug = false) =>
  postEvent({ logger: loggerProvider({ name: 'my-logger', debug }), url });
