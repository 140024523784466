import Page from '@rategravity/frontend/components/page';
import '@rategravity/frontend/theme/index.styl';
import { browserInit as segment } from '@rategravity/frontend/modules/segment';
import { pageViewObservable } from '@rategravity/frontend/modules/user-actions';
import createHistory from 'history/createBrowserHistory';
import React from 'react';
import { render } from 'react-dom';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import Header from './components/header';
import Footer from './components/footer';
import './index.styl';
import Affordability from './pages/affordability';
import ApprovedPage from './pages/approved';
import { BuyingPage } from './pages/buying';
import GetStarted from './pages/get-started';
import StartNow from './pages/start-now';
import PreapprovalPage from './pages/preapproval';
import PreapprovedPage from './pages/preapproved';
import RefinancingPage from './pages/refinancing';
import SixMonthsOut from './pages/six-months-out';
import { firstPartyTrackingObserver, trackingInstance } from './modules/1pt-observer';
import { TrackingProvider } from '@rategravity/1pt-lib';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { buildLink } from './modules/build-link';

const history = createHistory();

const RedirectToOnboarding = path => () => {
  // buildLink will check current url for any query parameters to append
  const link = buildLink(`${process.env.ONBOARDING_URL}/${path}`);
  window.location = link;
  return null;
};

const actionPipe = new ReplaySubject(10);
// Route page view actions to the action pipe
pageViewObservable(history).subscribe(actionPipe);

segment(actionPipe);

actionPipe.subscribe(firstPartyTrackingObserver());

const splitUrl = process.env.SPLIT_URL || 'https://staging.split.ownup.com';
const splitConfig = {
  core: {
    authorizationKey: process.env.SPLIT_API_KEY || 'qdjduua8u5orl12gguii27vam9g73rccr5lf',
    key: trackingInstance.getAnonymousId(),
    trafficType: 'Anonymous'
  },
  urls: {
    sdk: `${splitUrl}/sdk-api`,
    events: `${splitUrl}/events-api`,
    auth: `${splitUrl}/auth-api`
  }
};

render(
  <TrackingProvider value={trackingInstance}>
    <SplitFactory config={splitConfig}>
      <Page
        className="page page__container"
        header={<Header />}
        footer={<Footer copyHoverColor="primary" />}
      >
        <Router history={history}>
          <Switch>
            <Route
              path="/"
              exact
              render={() => {
                window.location.href = 'https://ownup.com';
                return null;
              }}
            />
            <Route path="/buying">
              <BuyingPage actionPipe={actionPipe} />
            </Route>
            <Route path="/approved">
              <ApprovedPage actionPipe={actionPipe} />
            </Route>
            <Route path="/preapproved" component={PreapprovedPage} />
            <Route path="/preapproval" component={RedirectToOnboarding('preapproval')} />
            <Route path="/refinancing" component={RedirectToOnboarding('refinance')} />
            <Route path="/accepted" component={RedirectToOnboarding('accepted-offer')} />
            <Route path="/affordability">
              <Affordability actionPipe={actionPipe} />
            </Route>
            <Route path="/offered" component={RedirectToOnboarding('accepted-offer')} />
            <Route path="/unoffered" component={RedirectToOnboarding('accepted-offer')} />
            <Route path="/rate-and-term" component={RedirectToOnboarding('refinance')} />
            <Route path="/cash-out" component={RedirectToOnboarding('refinance')} />
            <Route path="/purchase" component={RedirectToOnboarding('purchase')} />
            <Route path="/get-started" component={GetStarted} />
            <Route path="/start-now" component={StartNow} />
            <Route path="/6-months-out">
              <SixMonthsOut actionPipe={actionPipe} />
            </Route>
            <Route
              path="/6-months-out-preapproval"
              component={RedirectToOnboarding('preapproval')}
            />
            <Redirect from="*" to="/get-started" />
          </Switch>
        </Router>
      </Page>
    </SplitFactory>
  </TrackingProvider>,
  document.getElementById('root')
);
