import React from 'react';
import OptionPanel from '../components/option-panel';
import FunnelAction from '../components/funnel-action';
import Preapproval from '../images/preapproval.svg';
import Piggy from '../images/piggy.svg';
import SlideInPage from '../components/slidein-page';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

const SixMonthsOut = () => (
  <OwnUpComponentLibraryProvider theme={ownUpTheme}>
    <OwnUpGridWrapper>
      <DiscoverGridContainer spacing={2}>
        <SlideInPage title={'Own Up | Buying - Save an average of $21,000 on your mortgage'}>
          <OptionPanel title="How can we help you today?">
            <FunnelAction
              text="I’d like to get a pre-approval letter."
              src={Preapproval}
              to="6-months-out-preapproval"
            />
            <FunnelAction
              text="Just help me understand my affordability."
              src={Piggy}
              to="affordability"
            />
          </OptionPanel>
        </SlideInPage>
      </DiscoverGridContainer>
    </OwnUpGridWrapper>
  </OwnUpComponentLibraryProvider>
);

export default SixMonthsOut;
