import React, { useEffect } from 'react';
import OptionPanel from '../components/option-panel';
import FunnelAction from '../components/funnel-action';
import OpenHouses from '../images/discover-door.svg';
import MagnifyingGlass from '../images/magnifying-glass.svg';
import OffsetCheck from '../images/offset-check.svg';
import { event } from '@rategravity/frontend/modules/user-actions';
import SlideInPage from '../components/slidein-page';
import { buildLink } from '../modules/build-link';
import {
  OwnUpComponentLibraryProvider,
  OwnUpGridWrapper,
  ownUpTheme
} from '@rategravity/own-up-component-library-new';
import { DiscoverGridContainer } from '../components/grid';

export const BuyingPage = ({ actionPipe }) => {
  useEffect(() => {
    actionPipe.next(
      event('Entered Product Funnel', {
        funnel: 'purchase'
      })
    );
  });

  const aoLink = buildLink(`${process.env.ONBOARDING_URL}/accepted-offer`);
  return (
    <OwnUpComponentLibraryProvider theme={ownUpTheme}>
      <OwnUpGridWrapper>
        <DiscoverGridContainer spacing={2}>
          <SlideInPage title={'Own Up | Buying - Get the best deal on your mortgage'} index>
            <OptionPanel title="How exciting! Where in the home purchasing process are you?">
              <div className={`funnelAction`}>
                <a className="funnelAction__link" href={aoLink}>
                  <img src={OffsetCheck} />
                  <h5 className="funnelAction__text"> I have an accepted offer! </h5>
                </a>
              </div>
              <FunnelAction
                text="I'm going to open houses."
                src={OpenHouses}
                to="preapproved"
                onClick={() => {
                  actionPipe.next(event('Click Looking Around', { to: 'preapproved' }));
                }}
                actionPipe={actionPipe}
              />
              <FunnelAction
                text="I'm doing research."
                src={MagnifyingGlass}
                to="preapproved"
                onClick={() => {
                  actionPipe.next(event('Click Not Looking', { to: 'preapproved' }));
                }}
                actionPipe={actionPipe}
              />
            </OptionPanel>
          </SlideInPage>
        </DiscoverGridContainer>
      </OwnUpGridWrapper>
    </OwnUpComponentLibraryProvider>
  );
};
