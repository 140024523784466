import React from 'react';

const home = 'https://www.ownup.com';

const links = [
  {
    title: 'About',
    target: `${home}/about`
  },
  {
    title: 'Resource Center',
    target: 'https://resources.ownup.com'
  },
  {
    title: 'FAQ',
    target: `${home}/faq`
  },
  {
    title: 'Privacy',
    target: `${home}/privacy`
  },
  {
    title: 'Licensing',
    target: `${home}/licensing`
  },
  {
    title: 'Terms and Conditions',
    target: `${home}/terms-conditions`
  }
];

export const NavLink = ({ title, target, copyHoverColor }) => (
  <a className={`footer__nav hover__color--${copyHoverColor}`} href={target}>
    <h5>{title}</h5>
  </a>
);

export const NavLinks = ({ copyHoverColor }) => (
  <div className="footer__navs">
    {links.map(({ target, title }, l) => (
      <NavLink key={l} {...{ title, target, copyHoverColor }} />
    ))}
  </div>
);
