import { Typography } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { PropsWithTheme } from '../../core';
import { aktivGroteskMediumMixin, aktivGroteskRegularMixin } from '../../fonts';
import { OwnUpDetailProps } from './properties';

const detailMixin = css`
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const smallDetailMixin = css`
  font-size: 0.625rem;
  line-height: 1rem;
`;

const Detail = (props: OwnUpDetailProps) => <Typography {...props} />;

export const OwnUpDetail = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${aktivGroteskRegularMixin}
  ${detailMixin}
`;

export const OwnUpDetailMedium = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${aktivGroteskMediumMixin}
  ${detailMixin}
`;

export const OwnUpSmallDetail = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${aktivGroteskRegularMixin}
  ${smallDetailMixin}
`;

export const OwnUpSmallDetailMedium = styled(Detail)<PropsWithTheme<OwnUpDetailProps>>`
  ${aktivGroteskMediumMixin}
  ${smallDetailMixin}
`;
