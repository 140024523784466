import React from 'react';
import PropTypes from 'prop-types';
import './funnel-action.styl';
import { Link } from 'react-router-dom';
import { buildLink } from '../../modules/build-link';

const FunnelAction = ({ className, text, src, to, query, onClick }) => {
  const link = buildLink(`/${to}`, query);

  return (
    <div className={`funnelAction ${className}`}>
      <Link className="funnelAction__link" to={link} onClick={onClick}>
        <img {...{ src }} style={{ width: '156px' }} />
        <h5 className="funnelAction__text"> {text} </h5>
      </Link>
    </div>
  );
};

FunnelAction.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

FunnelAction.defaultProps = {
  className: '',
  onClick: () => {}
};

export default FunnelAction;
