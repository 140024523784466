/* eslint-disable @typescript-eslint/naming-convention */
import { createTrackingInstance } from '@rategravity/1pt-lib';

interface Action {
  type: 'Event' | 'PageView';
}

interface EventAction extends Action {
  id?: string;
  properties?: Record<string, unknown>;
}

interface Handler {
  Event: (payload: EventAction) => void;
  PageView: () => void;
}

type TrackingInstance = ReturnType<typeof createTrackingInstance>;

export const firstPartyTrackingHandler = (trackingInstance: TrackingInstance): Handler => ({
  Event: ({ id, properties = {} }) => {
    if (id) {
      trackingInstance.track(id, properties);
    }
  },
  PageView: () => {
    trackingInstance.page();
  }
});

const observer = (handler: Handler) => (action: Action) => handler[action.type](action);

const isLocal = !window.location.origin.includes('.ownup.com');
export const trackingInstance = createTrackingInstance({ url: '/events', local: isLocal });

/** This will subscribe to the action pipe and send events to the 1pt endpoint **/
export const firstPartyTrackingObserver = () => {
  return observer(firstPartyTrackingHandler(trackingInstance));
};
