import { v4 as uuid } from 'uuid';
import { getId, getUser, removeCookie, setId, setUser } from './utils/cookies';
import { Event, postFactory } from './utils/fetch';
import { Context, getContext } from './utils/get-page-data';

interface User {
  accountId: string;
  properties: Record<string, unknown>;
}

interface InitConfig {
  getPageContext: () => Context;
  postImpression: (arg: Event) => void;
  setId: (arg: string) => void;
  getId: () => string | undefined;
  removeId: () => void;
  setUser: (accountId: string, properties: Record<string, unknown>) => void;
  getUser: () => User | undefined;
  removeUser: () => void;
}

const initializationConfig = ({
  idKey,
  userKey,
  url,
  debug,
  local
}: {
  idKey: string;
  userKey: string;
  url: string;
  debug: boolean;
  local: boolean;
}): InitConfig => ({
  getPageContext: getContext,
  postImpression: postFactory(url, debug),
  setId: setId(idKey, local),
  getId: getId(idKey),
  removeId: removeCookie(idKey, local),
  getUser: getUser(userKey),
  setUser: setUser(userKey),
  removeUser: removeCookie(userKey)
});

const initialize = ({ getId, setId }: Pick<InitConfig, 'getId' | 'setId'>) => {
  if (!getId()) {
    setId(uuid());
  }
};

/**
 * Call to record a page view. Automatically records important page details.
 */
const page =
  ({
    getPageContext,
    getId,
    postImpression,
    getUser
  }: Pick<InitConfig, 'postImpression' | 'getUser' | 'getId' | 'getPageContext'>) =>
  () => {
    const user = getUser();
    postImpression({
      properties: getPageContext().page,
      accountId: user ? user.accountId : undefined,
      anonymousId: getId() as string,
      context: getPageContext(),
      type: 'page'
    });
  };

/**
 * Call to send an event.
 * @param event - The event name.
 * @param properties - Any data that goes along with the event.
 */
const track =
  ({
    getPageContext,
    getId,
    postImpression,
    getUser
  }: Pick<InitConfig, 'postImpression' | 'getUser' | 'getId' | 'getPageContext'>) =>
  (event: string, properties: Record<string, unknown> = {}) => {
    const user = getUser();
    postImpression({
      properties: properties,
      accountId: user ? user.accountId : undefined,
      anonymousId: getId() as string,
      context: getPageContext(),
      type: 'track',
      event
    });
  };

/**
 * Call when a user gets a valid accountId.
 * @param accountId - The user's account id.
 * @param properties - Any user properties ex: loan type
 */
const identify =
  ({
    getPageContext,
    getId,
    postImpression,
    setUser
  }: Pick<InitConfig, 'postImpression' | 'setUser' | 'getId' | 'getPageContext'>) =>
  (accountId: string, properties: Record<string, unknown> = {}) => {
    setUser(accountId, properties);
    postImpression({
      properties,
      accountId: accountId,
      anonymousId: getId() as string,
      context: getPageContext(),
      type: 'identify'
    });
  };

interface UserConfig {
  /** The endpoint to send events to. */
  url: string;
  /** Set true to log event payloads. Default is false. */
  debug?: boolean;
  /** Set true for local testing. This will allow the cookies to be accessed outside the ownup.com domain **/
  local?: boolean;
  idKey?: string;
  userKey?: string;
  getInitializationConfig?: () => InitConfig;
}

export const createTrackingInstance = (userConfig: UserConfig) => {
  const {
    url,
    debug = false,
    local = false,
    idKey = 'OU_1PT',
    userKey = 'OU_1PT_USER',
    getInitializationConfig = initializationConfig
  } = userConfig;

  const initConfig = getInitializationConfig({ idKey, userKey, url, debug, local });
  const { getId, removeId, getUser, removeUser } = initConfig;

  initialize(initConfig);

  return {
    page: page(initConfig),
    track: track(initConfig),
    identify: identify(initConfig),
    reset: () => {
      if (getId()) {
        removeId();
      }
      if (getUser()) {
        removeUser();
      }
    },
    getAnonymousId: getId
  };
};

export type TrackingInstance = ReturnType<typeof createTrackingInstance>;
