import Box, { BoxProps } from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import styled from 'styled-components';
import { PropsWithTheme } from '../../core';
import { OwnUpGridWrapperProps } from './properties';

/**
 * {@link Box} component with dynamic size and margins based
 *   on the current screen size.
 */
const StyledGridWrapper = styled(Box)<PropsWithTheme<BoxProps>>`
  max-width: ${({ theme }: PropsWithTheme<BoxProps>) => {
    // Maximum width is equal to the xl breakpoint size
    return `${theme.breakpoints.values.xl}px`;
  }};
  margin: ${({ theme }: PropsWithTheme<BoxProps>) => {
    // Dynamically set the margin based on the current screen size

    // Hooks must always be called in the same order so we must run
    //   all three before returning, even if we only use some of them.
    const screenSizeXSmallOrBelow = useMediaQuery(theme.breakpoints.down('xs'));
    const screenSizeMediumOrBelow = useMediaQuery(theme.breakpoints.down('md'));
    // This query is used to switch us to "auto" margins when we have reached
    //   a screen size that can fit 80px on both sides of the container.
    // It is sort of a hacky way to do "auto" margins with a "minimum" of
    //   however many pixels.
    const screenSizeAboveXLarge = useMediaQuery(
      `(min-width: ${theme.breakpoints.values.xl + theme.spacing(10) * 2}px)`
    );

    // xsmall breakpoint and down: 3x spacing factor (8px * 3 = 24px)
    if (screenSizeXSmallOrBelow) {
      return theme.spacing(0, 3);
    }
    // medium breakpoint and down: 6 spacing factor (8px * 6 = 48px)
    if (screenSizeMediumOrBelow) {
      return theme.spacing(0, 6);
    }
    // above xl: 'auto'
    if (screenSizeAboveXLarge) {
      return 'auto';
    }
    // all others: 10x spacing factor (8px * 10 = 80px)
    return theme.spacing(0, 10);
  }};
`;

/**
 * A wrapper around Material UI's {@link Box} component that
 *   enforces margins and size limitations for the grid it
 *   should contain.
 *
 * Should be used to wrap all {@link OwnUpGrid} layouts.
 *
 *  - ZeroHeight: {@link https://zeroheight.com/7b3231350/p/54c9c6-grid}
 *  - Material UI: {@link https://material-ui.com/api/box/}
 *
 * @param props - {@link OwnUpGridWrapperProps}
 */
export const OwnUpGridWrapper = (props: OwnUpGridWrapperProps) => <StyledGridWrapper {...props} />;
