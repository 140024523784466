import React from 'react';
import { animateScroll } from 'react-scroll';
import './transitions.styl';

let loaded = false;
const shouldScroll = shouldAnimate => {
  const r = loaded || document.referrer === 'https://ownup.com';
  loaded = true;
  return r && shouldAnimate;
};

export const SlideIn = ({ children, className, shouldAnimate = true }) => {
  animateScroll.scrollToTop({
    duration: 0,
    smooth: false,
    offset: 0,
    delay: 0
  });
  return (
    <div className={`${shouldScroll(shouldAnimate) ? 'slide__in' : ''} ${className}`}>
      {children}
    </div>
  );
};
