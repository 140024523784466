import Link from '@material-ui/core/Link';
import React from 'react';
import { ArrowLeftIcon } from '../icon-library/system-icons/standard-icons/arrow-left';
import { ArrowRightIcon } from '../icon-library/system-icons/standard-icons/arrow-right';
import { OwnUpIcon } from '../icons/component';
import { OwnUpBodyMedium, OwnUpLargeBodyMedium, OwnUpSmallBodyMedium } from '../typography';
import { StyledIconLinkProps, StyledLink } from './properties';
import { IconLinkWrapper } from './styles';

/**
 *
 * @param link - The link url. Refers to the href location for this link.
 * @param wrapperRef - (optional) ref element to access <IconLinkWrapper>
 * @param styledLinkRef - (optional) ref element to access <StyledLink>
 * @param themeName - (optional) The theme name. Refers to the background color behind the input. Values supported are 'white', 'light', 'midtone' or 'dark'
 * @param iconPlacement - (optional) Refers to the location of the arrow icon. Values supported are 'left' and 'right'.
 * @param size - (optional) Refers to the size of the icon font and the arrow height. Values supported are 'small', 'default', and 'large'
 */
export const IconLink = ({
  link,
  wrapperRef = undefined,
  styledLinkRef = undefined,
  iconPlacement = 'right',
  size = 'default',
  children
}: StyledIconLinkProps) => (
  <IconLinkWrapper iconPlacement={iconPlacement} size={size} ref={wrapperRef} {...Link}>
    <StyledLink href={link} underline="none" ref={styledLinkRef}>
      {iconPlacement === 'left' ? <OwnUpIcon icon={ArrowLeftIcon} /> : null}
      {size === 'small' && <OwnUpSmallBodyMedium variant="body2">{children}</OwnUpSmallBodyMedium>}
      {size === 'large' && <OwnUpLargeBodyMedium variant="body2">{children}</OwnUpLargeBodyMedium>}
      {size === 'default' && <OwnUpBodyMedium variant="body2">{children}</OwnUpBodyMedium>}
      {iconPlacement === 'right' ? <OwnUpIcon icon={ArrowRightIcon} /> : null}
    </StyledLink>
  </IconLinkWrapper>
);
