import React from 'react';
import { css } from 'styled-components';

export interface ElementOutlineMixinProps {
  /**
   * Border inset on all sides. e.g. setting this to 2px
   *   will have the border sit 2px inwards on the parent
   *   component.
   */
  inset?: React.CSSProperties['top'];
  /**
   * Border color
   */
  color?: React.CSSProperties['borderColor'];
  /**
   * Border width
   */
  width?: React.CSSProperties['borderWidth'];
  /**
   * Border style
   */
  style?: React.CSSProperties['borderStyle'];
  /**
   * Border radius
   */
  radius?: React.CSSProperties['borderRadius'];
}

/**
 * Mixin to create a Safari-compatible outline pseudo-element.
 *   Because Safari's support for the "outline" property isn't
 *   great yet, we have to use other means.
 *
 * The reason to use a pseudo-element instead of a box shadow
 *   is that box-shadows do not support inner offsetting that
 *   well.
 *
 * Note that this mixin will set the affected element's `position`
 *   property to `relative`.
 *
 * @param {@see ElementOutlineMixinProps}
 * @returns CSS to be injected into another styled component
 */
export const createElementOutlineMixin = ({
  color = '',
  inset = '0',
  width = '1px',
  style = 'solid',
  radius = '0'
}: ElementOutlineMixinProps) => css`
  outline: none;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: ${inset};
    bottom: ${inset};
    left: ${inset};
    right: ${inset};
    border-radius: ${radius};
    border: ${width} ${style} ${color};
  }
`;
